<template>
  <li class="relative pl-4 pr-6 py-5 hover:bg-gray-50 group sm:py-6 sm:pl-6 lg:pl-8 xl:pl-6 mb-5 mr-5">
    <div class="flex items-center justify-between space-x-4">
      <!-- Repo name and link -->
      <div class="min-w-0 space-y-3">
        <div class="flex items-center space-x-3">
          <span
              class="h-4 w-4 bg-gray-100 group-hover:bg-green-100 rounded-full flex items-center justify-center"
              aria-hidden="true"
          >
            <span class="h-2 w-2 bg-gray-400 group-hover:bg-green-400 rounded-full"></span>
          </span>
          <!-- eslint-disable vue/no-v-html -->
          <span v-html="$options.filters.highlight(workspace.name, searchFilter)"></span>
          <!-- eslint-enable -->
        </div>
        <div class="relative group flex items-center space-x-2.5">
          <p
              v-for="(experiment, idx) in workspace.experiments"
              :key="experiment.id"
              class="flex text-gray-500 text-sm space-x-2"
          >
            <button
                class="hover:text-indigo-700 cursor-pointer"
                @click="onWorkspaceListItemClick(experiment, workspace)"
            >{{experiment.name}}</button>
            <span v-if="idx !== workspace.experiments.length - 1" aria-hidden="true">&middot;</span>
          </p>
        </div>
      </div>

      <!-- Repo meta info -->
      <div class="flex flex-col flex-shrink-0 items-end space-y-3">
        <button
            class="flex items-center space-x-4 relative"
            @click="$emit('setWorkspace', workspace.id)"
        >
          <span
              class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
          >Sæt som aktivt workspace</span>
        </button>
        <button
            class="flex items-center space-x-4 relative"
            @click="$emit('openAssignWorkspace', workspace)"
        >
          <span class="text-sm text-gray-700 hover:text-gray-900 font-medium">Ryk gruppe</span>
        </button>
        <button
            class="flex text-gray-500 text-sm space-x-2 cursor-pointer hover:text-red-700"
            @click.stop="deleteWorkspaceConfirmBox(workspace)"
        >Slet</button>
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'WorkspaceListItem',
  props: {
    workspace: {
      type: Object,
      default: () => [],
    },
    searchFilter: {
      type: String,
      default: '',
    },
  },
  methods: {
    async deleteWorkspaceConfirmBox({ id, name }) {
      try {
        await this.$confirm({
          title: `Slet workspace herunder alle eksperimenter (inkl. Main) og mellemtømninger for ${name}`,
          bodyText: 'Dette kan ikke laves om eller gendannes og er sidste advarsel',
        });
        this.$emit('deleteWorkspace', id);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    onWorkspaceListItemClick(experiment, workspace) {
      const id = experiment.id ?? workspace.id;
      this.$emit('setWorkspace', id);
    },
  },
};
</script>

<style>
</style>
