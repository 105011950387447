<template>
  <div class="w-full mx-auto desktop-container pb-24">
    <header class="pb-6 pt-8 border-b">
      <div class="flex justify-between">
        <h2 class="text-2xl font-extrabold text-gray-900">Workspace</h2>
      </div>
      <job-poller
          v-if="buildExperimentJobId"
          title="Opretter.."
          description="Dette kan tage nogle minutter, hvis der er mange opgaver"
          :job-id="buildExperimentJobId"
          :type="jobTypes.BUILD_EXPERIMENT"
          @error="onError"
          @result="onCreateExperimentResult"
      ></job-poller>

      <job-poller
          v-if="deleteExperimentJobId"
          title="Sletter.."
          description="Dette kan tage nogle minutter, hvis der er mange opgaver"
          :job-id="deleteExperimentJobId"
          :type="jobTypes.DELETE_EXPERIMENT"
          @error="onError"
          @result="onDeleteExperimentResult"
      ></job-poller>

      <job-poller
          v-if="copyWorkspaceJobId"
          title="Kopierer.."
          description="Dette kan tage nogle minutter, hvis det er et stort workspace"
          :job-id="copyWorkspaceJobId"
          :type="jobTypes.COPY_WORKSPACE"
          @error="onError"
          @result="onCopyWorkspaceResult"
      ></job-poller>

      <job-poller
          v-if="deleteWorkspaceJobId"
          title="Sletter helt workspace"
          description="Dette kan tage nogle minutter, hvis der er mange opgaver"
          :job-id="deleteWorkspaceJobId"
          :type="jobTypes.DELETE_WORKSPACE"
          @error="onError"
          @result="onDeleteWorkspaceResult"
      ></job-poller>

      <base-alert v-if="createdWorkspace" class="mt-8" type="warning">
        <div slot="title" class="flex justify-between w-full">
          <span
              class="text-sm leading-5 font-medium flex justify-between text-yellow-800"
          >Du er stadig i dit gamle workspace</span>

          <button
              class="flex items-center space-x-2 relative"
              @click="onSetWorkspace(createdWorkspace.id)"
          >
            <span
                class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
            >Sæt {{createdWorkspace.name}} som aktivt workspace</span>

            <icon-arrow-right class="text-indigo-700 h-4 w-4"></icon-arrow-right>
          </button>
        </div>
      </base-alert>

      <base-alert v-if="createdExperiment" class="mt-8" type="warning">
        <div slot="title" class="flex justify-between w-full">
          <span
              class="text-sm leading-5 font-medium flex justify-between text-yellow-800"
          >Du har endnu ikke skiftet til dit nye eksperiment</span>

          <button
              class="flex items-center space-x-2 relative"
              @click="onSetWorkspace(createdExperiment.id)"
          >
            <span
                class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
            >Skift til eksperiment: {{createdExperiment.name}}</span>

            <icon-arrow-right class="text-indigo-700 h-4 w-4"></icon-arrow-right>
          </button>
        </div>
      </base-alert>

      <base-alert v-if="copiedWorkspace" class="mt-8" type="warning">
        <div slot="title" class="flex justify-between w-full">
          <span
              class="text-sm leading-5 font-medium flex justify-between text-yellow-800"
          >Du har endnu ikke skiftet til din nye kopi</span>

          <button
              class="flex items-center space-x-2 relative"
              @click="onSetWorkspace(copiedWorkspace.id)"
          >
            <span
                class="text-sm text-indigo-700 hover:text-indigo-900 font-medium"
            >Skift til kopi: {{copiedWorkspace.name}}</span>

            <icon-arrow-right class="text-indigo-700 h-4 w-4"></icon-arrow-right>
          </button>
        </div>
      </base-alert>
    </header>

    <base-alert
        v-if="!activeWorkspace.id"
        title="Vælg et workspace fra listen eller opret et nyt"
        type="warning"
        class="mt-4"
    >
      <base-button color="indigo" class="mt-6" @click="showCreate = true">Nyt workspace</base-button>
      <base-button
          color="gray"
          class="ml-2"
          :outline="true"
          @click="showCreateGroup = true"
      >Ny gruppe</base-button>

      <base-button
          color="gray"
          class="ml-2"
          :outline="true"
          @click="showRenameGroupModal"
      >Omdøb en gruppe</base-button>

      <base-button
          color="gray"
          class="ml-2"
          :outline="true"
          @click="showMoveGroupModal = true"
      >Ryk gruppe</base-button>

      <base-button
          color="gray"
          class="ml-2"
          :outline="true"
          @click="showDeleteGroupModal = true"
      >Slet gruppe</base-button>
    </base-alert>

    <!-- Left sidebar & main wrapper -->
    <div v-loading="isLoading || hasRunningJob" class="flex-1 min-w-0 xl:flex mt-4">
      <div v-if="activeWorkspace.id" class="flex-shrink-0 w-64 mr-12">
        <div class="flex items-center justify-between">
          <div class="flex-1 px-4 mt-4">
            <div class="sm:items-center block">
              <!-- Active workspace -->
              <div class="flex-shrink-0 flex flex-col items-start space-y-2">
                <div class="flex items-center space-x-3">
    <span class="h-8 w-8 flex-shrink-0 bg-green-100 rounded-full flex items-center justify-center">
      <span class="h-3 w-3 bg-green-400 rounded-full"></span>
    </span>

                  <div class="flex flex-col">
                    <div
                        v-if="activeWorkspace"
                        class="text-sm font-medium text-gray-900"
                    >{{activeWorkspace.name}}</div>
                    <span
                        class="text-sm text-gray-500 group-hover:text-gray-900 font-medium"
                    >{{activeExperimentSubTitle}}</span>
                  </div>
                </div>

                <div
                    v-if="activeWorkspaceGroupPath"
                    class="text-xs text-gray-400 ml-11"
                >
                  <p class="text-sm font-medium text-gray-900">
                    Gruppe: <span class="font-normal">{{activeWorkspaceGroupPath}}</span>
                  </p>
                </div>
              </div>
              <!-- Action buttons -->
              <div class="flex flex-col sm:flex-row xl:flex-col mt-8 space-y-2">
                <div
                    v-if="isLightWorkspace"
                    class="bg-blue-100 border border-blue-200 h-20 w-64 rounded-md flex-center"
                >
                  <p class="text-blue-700 font-semibold">Ekstrakørsler</p>
                </div>

                <base-button color="indigo" @click="showCreate = true">Nyt workspace</base-button>

                <template v-if="activeWorkspaceId">
                  <base-button
                      v-if="isInMainExperiment && !isLightWorkspace"
                      color="gray"
                      :outline="true"
                      @click="showCopy = true"
                  >Kopier workspace</base-button>

                  <base-button
                      color="gray"
                      :outline="true"
                      @click="showRename = true;"
                  >Omdøb {{ renameLabel }}</base-button>

                  <base-button
                      v-if="isInMainExperiment && groupsLoaded"
                      color="gray"
                      :outline="true"
                      @click="onOpenAssignWorkspace(activeWorkspace)"
                  >Ryk workspace</base-button>

                  <base-button
                      v-if="!isLightWorkspace"
                      color="gray"
                      :outline="true"
                      :disabled="activeWorkspace.experiments.length >= 3 || isInTransitionPeriod"
                      @click="onClickCreateExperiment"
                  >Tilføj eksperiment</base-button>

                  <base-button
                      v-if="isInMainExperiment && !isInTransitionPeriod"
                      color="red"
                      :inverted="true"
                      @click="deleteWorkspaceConfirmBox"
                  >Slet workspace</base-button>

                  <div class="pt-3"></div>

                  <base-button
                      v-if="isInMainExperiment"
                      color="gray"
                      :outline="true"
                      @click="showCreateGroup = true"
                  >Ny gruppe</base-button>

                  <base-button
                      v-if="isInMainExperiment"
                      color="gray"
                      :outline="true"
                      @click="showRenameGroupModal"
                  >Omdøb gruppe</base-button>

                  <base-button
                      v-if="isInMainExperiment"
                      color="gray"
                      :outline="true"
                      @click="showMoveGroupModal = true"
                  >Ryk gruppe</base-button>

                  <base-button
                      v-if="isInMainExperiment"
                      color="red"
                      :inverted="true"
                      @click="showDeleteGroupModal = true"
                  >Slet gruppe</base-button>

                  <base-button
                      v-if="!isInMainExperiment"
                      color="gray"
                      :outline="true"
                      @click="onClickReturnToMain"
                  >Tilbage til Main</base-button>

                  <base-button
                      v-if="!isInMainExperiment && !isInTransitionPeriod && !isLightWorkspace"
                      color="indigo"
                      :inverted="true"
                      @click="onSetExperimentAsMainConfirmBox"
                  >Gør til Main</base-button>
                  <p
                      v-if="activeWorkspace.experiments.length >= 3"
                      class="text-sm text-yellow-700"
                  >Du kan maks. have 3 eksperimenter</p>
                </template>

              </div>
            </div>

            <template v-if="activeWorkspaceId && !isLightWorkspace">
              <!-- Experiments -->
              <template v-if="!isInTransitionPeriod">
                <div class="text-gray-700 font-medium mt-4">Eksperimenter</div>

                <ul
                    v-if="activeWorkspace.experiments.length"
                    class="flex flex-col space-y-5 mt-4 space-x-0"
                >
                  <li
                      v-for="experiment in activeWorkspace.experiments"
                      :key="experiment.id"
                      class="flex items-center justify-between mt-1 cursor-pointer"
                  >
                    <button class="flex items-center group" @click="onSetWorkspace(experiment.id)">
                      <icon-beaker
                          :class="[(experiment.id === activeWorkspace.activeExperimentId) && !isInTransitionPeriod ? 'text-indigo-700' : '']"
                          class="h-5 w-5 text-gray-300 group-hover:text-indigo-700"
                      ></icon-beaker>
                      <span
                          :class="[(experiment.id === activeWorkspace.activeExperimentId) && !isInTransitionPeriod ? 'text-indigo-700' : 'text-gray-500']"
                          class="text-sm group-hover:text-indigo-700 font-medium ml-2"
                      >{{experiment.name}}</span>
                    </button>

                    <button
                        v-if="experiment.id"
                        @click="deleteChildConfirmBox({id: experiment.id, name: experiment.name, title: 'Eksperiment'})"
                    >
                      <icon-trash class="text-gray-400 hover:text-red-500 h-5 w-5"></icon-trash>
                    </button>
                  </li>
                </ul>
                <div v-else class="text-sm text-gray-700 mt-2 italic">Ingen</div>
              </template>

              <!-- Transition period -->
              <div class="text-gray-700 font-medium mt-6">Mellemtømninger</div>

              <ul class="flex flex-col space-y-5 mt-4 space-x-0">
                <li
                    v-for="(tp, index) in activeWorkspace.transitionPeriods"
                    :key="index"
                    class="flex items-center justify-between mt-1 cursor-pointer"
                >
                  <button class="flex items-center group" @click="onSetWorkspace(tp.id)">
                    <icon-receipt-tax
                        class="h-5 w-5 group-hover:text-indigo-700"
                        :class="[activeWorkspaceId == tp.id ? 'text-indigo-700' : 'text-gray-500']"
                    ></icon-receipt-tax>
                    <span
                        :class="[activeWorkspaceId == tp.id ? 'text-indigo-700' : 'text-gray-500']"
                        class="text-sm group-hover:text-indigo-700 font-medium ml-2"
                    >{{tp.name}}</span>
                  </button>

                  <button
                      @click="deleteChildConfirmBox({id: tp.id, name: tp.name, title: 'Mellemtømninger'})"
                  >
                    <icon-trash class="text-red-400 hover:text-red-500 h-5 w-5"></icon-trash>
                  </button>
                </li>

                <li>
                  <div>
                    <router-link
                        v-if="hasPlanned && !isInTransitionPeriod"
                        :to="{name: 'transition-periods'}"
                        class="flex items-center text-gray-800 underline"
                    >
                      Opret mellemtømninger
                      <icon-arrow-right class="h-4 w-4 ml-2"></icon-arrow-right>
                    </router-link>
                    <span
                        v-else-if="!isInMainExperiment"
                        class="text-yellow-700 mt-2 block"
                    >Skift til Main for at oprette mellemtømninger</span>
                    <span v-else class="text-yellow-700 mt-2 block">Du har endnu ikke planlagt</span>
                  </div>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
      <div class="rounded-md min-w-0 flex-1">
        <div
            class="pl-4 pr-6 pt-4 pb-4 border-b border-t bg-white border-gray-200 sm:pl-6 lg:pl-8 xl:pl-6 xl:pt-6 xl:border-t-0"
        >
          <div class="flex items-center">
            <div class="relative w-full">
              <icon-search class="w-6 h-6 text-gray-400 absolute left-2 top-2.5"></icon-search>
              <input
                  v-model="search"
                  autocomplete="none"
                  type="search"
                  placeholder="Søg på gruppenavn.."
                  class="flex-1 border-none rounded-md w-full text-lg pl-10 text-gray-700"
              />
            </div>
          </div>
        </div>
        <ul v-if="filteredGroups.length" role="list">
          <workspace-list-group-item
              v-for="group in filteredGroups"
              :key="group.id"
              :group="group"
              :search-filter="search"
              @setWorkspace="onSetWorkspace($event)"
              @deleteWorkspace="deleteWorkspaceConfirmed($event)"
              @openAssignWorkspace="onOpenAssignWorkspace($event)"
              @renameGroup="openRenameGroupModal($event)"
          ></workspace-list-group-item>
        </ul>
        <image-container v-else asset-name="empty" title="Ingen matches fundet">
          <base-button color="indigo" class="mt-6" @click="showCreate = true">Opret nyt workspace</base-button>
        </image-container>
      </div>
    </div>

    <workspace-assign-group-modal
        v-model="showAssignGroup"
        :workspace="workspaceToAssign"
        @assigned="onAssignGroup"
    ></workspace-assign-group-modal>
    <workspace-create-modal v-model="showCreate" @create="onCeateWorkspace"></workspace-create-modal>
    <workspace-create-group-modal v-model="showCreateGroup" @create="onCreateGroup"></workspace-create-group-modal>
    <workspace-copy-modal
        v-model="showCopy"
        :current-name="activeWorkspace.name"
        @copy="onCopyWorkspace"
    ></workspace-copy-modal>
    <workspace-rename-modal
        v-model="showRename"
        :current-name="activeWorkspace.name"
        :is-in-transition-period="isInTransitionPeriod"
        @rename="onRenameWorkspace"
    ></workspace-rename-modal>
    <workspace-rename-group-modal
        v-model="showRenameGroup"
        :group-options="flatGroupOptions"
        @rename="onRenameGroup"
    ></workspace-rename-group-modal>
    <workspace-move-group-modal
        v-model="showMoveGroupModal"
        @assigned="onMoveGroup"
    ></workspace-move-group-modal>
    <workspace-delete-group-modal
        v-model="showDeleteGroupModal"
        @delete="onDeleteGroup"
    ></workspace-delete-group-modal>
    <workspace-experiment-create-modal v-model="showExperimentCreate" @create="onCeateExperiment"></workspace-experiment-create-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import ImageContainer from '@/components/ImageContainer.vue';
import WorkspaceCreateModal from '@/components/WorkspaceCreateModal.vue';
import WorkspaceRenameModal from '@/components/WorkspaceRenameModal.vue';
import WorkspaceCopyModal from '@/components/WorkspaceCopyModal.vue';
import WorkspaceListGroupItem from '@/components/WorkspaceListGroupItem.vue';
import WorkspaceExperimentCreateModal from '@/components/WorkspaceExperimentCreateModal.vue';
import WorkspaceAssignGroupModal from '@/components/WorkspaceAssignGroupModal.vue';
import WorkspaceMoveGroupModal from '@/components/WorkspaceMoveGroupModal';
import WorkspaceCreateGroupModal from '@/components/WorkspaceCreateGroupModal';
import WorkspaceRenameGroupModal from '@/components/WorkspaceRenameGroupModal';
import WorkspaceDeleteGroupModal from '@/components/WorkspaceDeleteGroupModal';

import {
  CREATE_DEPARTMENT,
  CREATE_GROUP,
  DELETE_DEPARTMENT,
  CREATE_EXPERIMENT,
  RENAME_DEPARTMENT,
  COPY_DEPARTMENT,
  DELETE_EXPERIMENT,
  ASSIGN_GROUP,
  ALL_GROUPS,
  ALL_DEPARTMENTS,
  RENAME_GROUP,
  UPGRADE_EXPERIMENT_TO_MAIN,
  MOVE_GROUP,
  DELETE_GROUP,
} from '@/store/actions.type';
import { SET_DEPARTMENT } from '@/store/mutations.type';
import * as jobTypes from '@/util/jobTypes';
import JobPoller from '@/components/JobPoller.vue';

export default {
  name: 'WorkspaceView',
  components: {
    WorkspaceRenameGroupModal,
    ImageContainer,
    WorkspaceCreateModal,
    WorkspaceCreateGroupModal,
    WorkspaceRenameModal,
    WorkspaceListGroupItem,
    WorkspaceCopyModal,
    WorkspaceExperimentCreateModal,
    WorkspaceAssignGroupModal,
    WorkspaceMoveGroupModal,
    WorkspaceDeleteGroupModal,
    JobPoller,
  },
  data() {
    return {
      search: '',
      showCreate: false,
      showCreateGroup: false,
      showRename: false,
      showCopy: false,
      showAssignGroup: false,
      workspaceToAssign: null,
      showExperimentCreate: false,
      isLoading: false,
      createdWorkspace: null,
      createdExperiment: null,
      copiedWorkspace: null,
      jobTypes,
      hasCompletedJob: false,
      showRenameGroup: false,
      groupToRename: { id: null, name: '' },
      showMoveGroupModal: false,
      groupToMove: null,
      groupsLoaded: false,
      showDeleteGroupModal: false,
    };
  },
  computed: {
    ...mapGetters('departments', ['nestedGroups', 'groupedWorkspaces', 'activeWorkspace', 'activeWorkspaceId', 'isInTransitionPeriod', 'isInMainExperiment', 'isLightWorkspace']),
    ...mapGetters('jobs', ['buildExperimentJobId', 'deleteExperimentJobId', 'copyWorkspaceJobId', 'deleteWorkspaceJobId']),
    ...mapGetters('status', ['hasPlanned']),
    flatGroupOptions() {
      return this.flattenGroupOptions(this.groupedWorkspaces);
    },
    filteredGroups() {
      const loweredSearch = this.search?.toLowerCase() || '';

      const filterGroup = (group) => {
        if (!group || typeof group.name === 'undefined') {
          return null;
        }

        const isGroupMatch = group.name.toLowerCase().includes(loweredSearch);

        const filteredWorkspaces = (group.workspaces || []).filter((workspace) => {
          if (!workspace || typeof workspace.name === 'undefined') {
            return false;
          }
          return workspace.name.toLowerCase().includes(loweredSearch);
        });

        const filteredGroups = (group.groups || [])
            .map(filterGroup)
            .filter((child) => child !== null);

        if (isGroupMatch) {
          return {
            ...group,
            workspaces: group.workspaces,
            groups: group.groups,
          };
        } if (filteredWorkspaces.length > 0 || filteredGroups.length > 0) {
          return {
            ...group,
            workspaces: filteredWorkspaces,
            groups: filteredGroups,
          };
        }

        return null;
      };

      return this.groupedWorkspaces.map(filterGroup).filter((group) => group !== null);
    },
    hasRunningJob() {
      return (this.buildExperimentJobId || this.deleteExperimentJobId || this.deleteWorkspaceJobId || this.copyWorkspaceJobId) && !this.hasCompletedJob;
    },
    renameLabel() {
      if (this.isInTransitionPeriod) {
        return 'mellemtømninger';
      }
      return this.isInMainExperiment ? 'workspace' : 'eksperiment';
    },
    activeExperimentSubTitle() {
      if (this.isInTransitionPeriod) {
        return this.activeWorkspace.activeTransitionPeriodName;
      }

      return this.activeWorkspace.activeExperimentName;
    },
    activeWorkspaceGroupPath() {
      if (!this.activeWorkspace || !this.activeWorkspace.id) {
        return null;
      }
      const path = this.getGroupPath(this.activeWorkspace.id);
      return path;
    },
  },
  async mounted() {
    try {
      await this.getAll();
      await this.getAllGroups();
      this.groupsLoaded = true;
    } catch (error) {
      this.$unhandledError(error);
    }
  },
  methods: {
    ...mapMutations('departments', {
      setWorkspace: SET_DEPARTMENT,
    }),
    ...mapActions('departments', {
      getAll: ALL_DEPARTMENTS,
      getAllGroups: ALL_GROUPS,
      assignGroup: ASSIGN_GROUP,
      createWorkspace: CREATE_DEPARTMENT,
      copyWorkspace: COPY_DEPARTMENT,
      renameWorkspace: RENAME_DEPARTMENT,
      deleteWorkspace: DELETE_DEPARTMENT,
      deleteExperiment: DELETE_EXPERIMENT,
      createExperiment: CREATE_EXPERIMENT,
      upgradeExperimentToMain: UPGRADE_EXPERIMENT_TO_MAIN,
      createGroup: CREATE_GROUP,
      renameGroup: RENAME_GROUP,
      moveGroup: MOVE_GROUP,
      deleteGroup: DELETE_GROUP,
    }),
    onDeleteGroup(groupId) {
      this.deleteGroup(groupId)
          .then(() => {
            this.$ntf.success('Gruppen blev slettet');
            this.getAllGroups();
          })
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.error) {
              this.$ntf.error(`Fejl ved sletning af gruppe: ${error.response.data.error}`);
            }
          });
    },
    onMoveGroup({ groupId, targetGroupId }) {
      this.moveGroup({ groupId, targetGroupId })
          .then(() => {
            this.$ntf.success('Gruppen blev flyttet');
            this.showMoveGroupModal = false;
          })
          .catch((error) => {
            this.$unhandledError(error, false);
            this.$ntf.error('Gruppen kunne ikke flyttes');
          });
    },
    openRenameGroupModal() {
      if (this.activeWorkspace && this.activeWorkspace.groupId) {
        this.groupToRename = {
          id: this.activeWorkspace.groupId,
          name: this.getGroupName(this.activeWorkspace.groupId),
        };
        this.showRenameGroup = true;
      } else {
        this.$ntf.warning('Dette workspace tilhører ikke en gruppe');
      }
    },
    getGroupName(groupId) {
      const group = this.findGroupById(groupId, this.groupedWorkspaces);
      return group ? group.name : '';
    },
    findGroupById(id, groups) {
      // eslint-disable-next-line no-restricted-syntax
      for (const group of groups) {
        if (group.id === id) return group;
        if (group.groups) {
          const found = this.findGroupById(id, group.groups);
          if (found) return found;
        }
      }
      return null;
    },
    flattenGroupOptions(groups, prefix = '') {
      return groups.reduce((acc, group) => {
        acc.push({
          value: group.id,
          label: prefix + group.name,
        });

        if (group.groups && group.groups.length > 0) {
          acc.push(...this.flattenGroupOptions(group.groups, `${prefix}${group.name} / `));
        }

        return acc;
      }, []);
    },
    showRenameGroupModal() {
      if (this.flatGroupOptions.length > 0) {
        this.showRenameGroup = true;
      } else {
        this.$ntf.warning('Der er ingen grupper at omdøbe');
      }
    },
    async onRenameGroup({ id, newName }) {
      // eslint-disable-next-line no-useless-catch
      try {
        await this.renameGroup({ id, newName });
        this.$ntf.success('Gruppen blev omdøbt');
        this.showRenameGroup = false;
        await this.getAllGroups();
      } catch (error) {
        throw error;
      }
    },
    async onCreateExperimentResult(result) {
      this.createdExperiment = result;
      this.hasCompletedJob = true;
      this.getAll();
    },
    async onCopyWorkspaceResult(result) {
      this.copiedWorkspace = result;
      this.hasCompletedJob = true;
      this.getAll();
    },
    onDeleteExperimentResult() {
      this.onSetWorkspace();
    },
    onOpenAssignWorkspace(workspace) {
      this.showAssignGroup = true;
      this.workspaceToAssign = workspace;
    },
    async onDeleteWorkspaceResult() {
      this.hasCompletedJob = true;

      this.getAll();
    },
    async deleteWorkspaceConfirmBox() {
      try {
        await this.$confirm({
          title: `Slet workspace herunder alle eksperimenter (inkl. Main) og mellemtømninger for ${this.activeWorkspace.name}`,
          bodyText: 'Dette kan ikke laves om eller gendannes og er sidste advarsel',
        });
        this.deleteWorkspaceConfirmed(this.activeWorkspaceId);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async deleteWorkspaceConfirmed(id) {
      try {
        this.$ntf.info('Sletter workspace');
        this.hasCompletedJob = false;
        await this.deleteWorkspace(id);
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error('Workspacet kunne ikke slettes');
      }
    },
    onError() {
      this.hasCompletedJob = true;
      this.isLoading = false;
    },
    async onCopyWorkspace(name) {
      try {
        this.$ntf.info('Starter kopiering af workspace');
        this.hasCompletedJob = false;
        this.showCopy = false;
        await this.copyWorkspace({ name });
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error('Workspacet kunne ikke kopieres');
      }
    },
    async deleteChildConfirmBox({ title, id, name }) {
      try {
        await this.$confirm({
          title: `Slet ${title}`,
          bodyText: `Vil du slette ${name}?`,
        });
        await this.deleteChildConfirmed(id, title);
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async deleteChildConfirmed(id, title) {
      try {
        this.$ntf.info(`Sletter ${title}`);
        this.hasCompletedJob = false;
        await this.deleteExperiment(id);
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error(`${title} kunne ikke slettes`);
      }
    },
    onClickReturnToMain() {
      this.onSetWorkspace(this.activeWorkspace.id);
    },
    onClickCreateExperiment() {
      // if the activeExperimentId has a value the user is attempting to create an experiment from another experiment
      if (this.activeWorkspace.activeExperimentId) {
        this.$ntf.warning('Skift til "Main" for at oprette et eksperiment i dit workspace');
      } else {
        this.showExperimentCreate = true;
      }
    },
    onSetWorkspace(workspaceId) {
      if (!workspaceId) {
        // eslint-disable-next-line no-param-reassign
        workspaceId = this.activeWorkspace?.id;
      }

      this.setWorkspace(workspaceId);

      this.$nextTick(() => {
        window.location.reload();
      });
    },
    async onCeateWorkspace(workspace) {
      try {
        const id = await this.createWorkspace(workspace);
        this.$ntf.success('Workspacet blev oprettet');
        this.showCreate = false;
        this.createdWorkspace = {
          ...workspace,
          id,
        };
        this.$store.commit('departments/ADD_WORKSPACE', this.createdWorkspace);
        await this.getAll();
        await this.getAllGroups();
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error(error?.data?.error ?? 'Der skete en uventet fejl');
      }
    },
    async onCreateGroup(groupData) {
      try {
        await this.createGroup(groupData);
        this.$ntf.success('Gruppen blev oprettet');
        this.showCreateGroup = false;

        await this.getAllGroups();
        await this.getAll();
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error(error?.response?.data?.error || error.message || 'Der skete en uventet fejl');
      }
    },
    async onRenameWorkspace(newName) {
      try {
        await this.renameWorkspace({ id: this.activeWorkspaceId, name: newName });
        this.$ntf.success(this.isInMainExperiment ? 'Workspacet blev rettet' : 'Eksperimentet blev rettet');
        this.showRename = false;
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error(error?.data?.error ?? 'Der skete en uventet fejl');
      }
    },
    async onAssignGroup(workspace) {
      try {
        await this.assignGroup(workspace);
        this.$ntf.success('Workspacet blev flyttet');
        this.showAssignGroup = false;
        this.workspaceToAssign = null;
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error(error?.data?.error ?? 'Der skete en uventet fejl');
      }
    },

    async onCeateExperiment(experiment) {
      if (experiment.name === 'Main') {
        this.$ntf.error('Et eksperiment kan ikke også hedde Main');
        return;
      }

      try {
        this.$ntf.info('Opretter eksperiment');
        this.hasCompletedJob = false;
        this.showExperimentCreate = false;
        await this.createExperiment(experiment);
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error('Eksperimentet kunne ikke oprettes');
      }
    },
    async onSetExperimentAsMainConfirmBox() {
      try {
        await this.$confirm({
          type: 'info',
          title: 'Opgrader eksperiment til at være Main',
          bodyText: 'Det nuværende Main vil blive omdøbt til Main (old)',
          confirmText: 'Bekræft',
        });
        await this.onSetExperimentAsMain();
        // eslint-disable-next-line no-empty
      } catch (error) {}
    },
    async onSetExperimentAsMain() {
      try {
        this.isLoading = true;
        this.$ntf.info('Opgraderer eksperiment');
        await this.upgradeExperimentToMain(this.activeWorkspace.activeExperimentId);
      } catch (error) {
        this.$unhandledError(error, false);
        this.$ntf.error('Opgraderingen kunne ikke gennemføres');
      } finally {
        this.isLoading = false;
      }
    },
    getGroupPath(workspaceId) {
      const result = this.findWorkspaceAndGroupPath(workspaceId, this.groupedWorkspaces);
      return result ? result.join('/') : null;
    },

    findWorkspaceAndGroupPath(workspaceId, groups, currentPath = []) {
      // eslint-disable-next-line no-restricted-syntax
      for (const group of groups) {
        const newPath = [...currentPath, group.name];

        const workspace = group.workspaces.find((ws) => ws.id === workspaceId);
        if (workspace) {
          return newPath;
        }

        if (group.groups && group.groups.length > 0) {
          const result = this.findWorkspaceAndGroupPath(workspaceId, group.groups, newPath);
          if (result) return result;
        }
      }
      return null;
    },
  },
};
</script>

<style>
.custom-gray-button {
  --button-color: #4b5563;
}
</style>
